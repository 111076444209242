import React from 'react'
import styled from 'styled-components'

const Ad = ({url}) => (
    <Wrapper className='extra'>
        <div className='ui orange button' style={{display: 'inline-block'}}>
            <i className='amazon icon'></i>
            <a href={url}  target='_blank' rel='noopener noreferrer' style={{color: '#fff'}}>Amazon</a>
        </div>
    </Wrapper>
)

const Wrapper = styled.div`
    text-align: right;
    margin: 20px 0 0 0;
`

export default Ad