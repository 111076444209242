import React from 'react'
import {Link} from 'gatsby'
import _ from 'lodash'
import {useLocation} from "@reach/router"

import Layout from '../components/layout'
import SNSButton from '../components/sns'
import Ad from '../components/ad'
import SEO from '../components/seo'
import useWindowSize from '../components/useWindowSize'

const BookDetail = ({pageContext: {prize, extract, thumbnail, relatedBooks}}) => {
    const {_id, prize_name, author, book, description, image_path, year, infobox, amazon_url} = prize

    const {pathname} = useLocation()
    const {width} = useWindowSize();
    const sp = width < 640;

    const meta = {
        title: `${prize['book']} - ${prize['author']}`,
        description: prize['description'] ? prize['description'].slice(0, 80) : '',
        img_url: image_path ? `/images/${prize_name}/${image_path}` : '/images/now_printing.jpg'
    }

    const Grid = props => (
        <div className='ui centered grid'>
            <div className={`${sp ? 'sixteen' : 'eight'} wide column`}>
                {props.children}
            </div>
        </div>
    );

    const BookItem = () => (
        <div className='item'>
            <div className='ui small image'>
                <img src={meta['img_url']} alt='cover'/>
            </div>
            <div className='content' style={{marginLeft: '2em'}}>
                <div className='header'><h1>{book}</h1></div>
                <div style={{marginTop: '5px'}}><h3>{author}</h3></div>
                <div className="ui divider"></div>
                <div className='description' style={{marginTop: '2em'}}>{description}</div>
            </div>
        </div>
    );

    const AuthorItem = () => (
        <div className='item'>
            <div className='ui tiny image'>
                {thumbnail && <img src={thumbnail} alt='thumbnail'/>}
            </div>
            <div className='content'>
                <div className='description'><h4>{author}</h4></div>
                <div className="ui divider"></div>
                {extract && <div className='description' style={{fontSize: '13px'}}>{extract}</div>}
            </div>
        </div>
    );

    const Infobox = () => {
        const keys = _.keys(infobox);

        return (
            <table className='ui very basic compact small table'>
                <thead>
                <tr>
                    <th className="three wide"></th>
                    <th className="thirteen wide"></th>
                </tr>
                </thead>
                <tbody>
                {keys.map(key =>
                    <tr key={key}>
                        <td>{key}</td>
                        <td>{infobox[key].slice(0, 36)}</td>
                    </tr>)}
                </tbody>
            </table>
        );
    };

    const RelatedBooks = () => (
        <div className='ui tiny middle aligned divided list'>
            {relatedBooks.map(book => (
                <div className='item'>
                    <img className='ui mini image' src={book['small_image_path']} alt={'book cover'}/>
                    <div className='middle aligned content'>
                        <Link to={`/book/${book['_id']}`}>
                            <div className='header'>{book['book']}</div>
                            {book['description'] ?
                                <div className='meta'
                                     style={{color: '#000'}}>{book['description'].slice(0, 30) + '...'}</div> : null}
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    )

    return (
        <Layout>
            <SEO title={meta['title']} description={meta['description']} image={meta['img_url']}/>
            <Grid>
                <div className='ui relaxed items'>
                    <BookItem/>
                    {/*<Ad url={amazon_url}/>*/}
                    <div className="ui divider"></div>
                    <AuthorItem/>
                </div>
                <Infobox/>
                <div className="ui divider"></div>
                <RelatedBooks/>
                <div className="ui divider"></div>
                <SNSButton style={{textAlign: 'right'}} url={`https://lndex.me${pathname}`}/>
            </Grid>
        </Layout>
    );
};

export default BookDetail;
